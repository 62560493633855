import React from "react";
import { useTranslation } from "react-i18next";
import { Moment } from "moment";
import { translations } from "../../i18n";
import { Form, Button, Select, Row, Col, Modal, DatePicker } from "antd";
const { Item } = Form;
const { Option } = Select;

interface Props {
  visible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  handleGoalDistanceChange: (value: string) => void;
  handleGoalDateChange: (date: Moment | null, dateString: string) => void;
  disableDate: (value: Moment) => boolean;
  type: number | string;
}

const GoalModal: React.FC<Props> = ({
  visible,
  handleOk,
  handleCancel,
  handleGoalDistanceChange,
  handleGoalDateChange,
  disableDate,
  type,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <Row>
        <Col span={9}>
          <Item label={t(translations.distance.title)} labelCol={{ span: 24 }}>
            <Select
              allowClear
              size="large"
              placeholder={t(translations.perfomanceModal.selectDistance)}
              onChange={handleGoalDistanceChange}
            >
              <Option value={5}>
                {t(translations.perfomanceModal["5km"])}
              </Option>
              <Option value={10}>
                {t(translations.perfomanceModal["10km"])}
              </Option>
              <Option value={21}>
                {t(translations.perfomanceModal.halfMarathon)}
              </Option>
              <Option value={42}>
                {t(translations.perfomanceModal.marathon)}
              </Option>
            </Select>
          </Item>
        </Col>
        <Col span={9} offset={1}>
          <Item
            label={t(translations.raceDate)}
            className="race-item"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              size="large"
              name="raceDate"
              disabledDate={disableDate}
              className="racedate-item w100"
              onChange={handleGoalDateChange}
              placeholder={t(translations.selectRaceDate)}
              showNow={false}
            />
          </Item>
        </Col>
        <Col span={3} offset={1} className="my-goal-ok">
          <Button key="1" onClick={handleOk} size="large" type="primary">
            {t(translations.perfomanceModal.ok)}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default GoalModal;
