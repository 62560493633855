import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { translations } from "../../i18n";
import { Form, Select, Row, Col, DatePicker, Typography, Button } from "antd";
import { StateKeys } from "../../types";
import { SelectDays } from "../../types";
import { Moment } from "moment";
import moment from "moment";
import { days, mapSelectedDays } from "../../utils";
import GoalModal from "./GoalModal";

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;

interface Props {
  onChangeAvailability: (value: string) => void;
  onChangeLongDay: (value: string) => void;
  onChangeStartDate: (date: Moment | null, dateString: string) => void;
  onChangeRaceDate: (date: Moment | null, dateString: string) => void;
  onDistanceGoalChanged: (value: string) => void;
  onDateGoalChanged: (date: Moment | null, dateString: string) => void;
  getValidateStatus: (name: StateKeys) => boolean;
  selectedDays: any;
  type: number | string;
  goalDate: string | number;
  goalDistance: string | number;
  shouldValidate: boolean;
}

const RaceItem: React.FC<Props> = ({
  onChangeAvailability,
  onChangeLongDay,
  onChangeStartDate,
  onChangeRaceDate,
  onDistanceGoalChanged,
  onDateGoalChanged,
  getValidateStatus,
  selectedDays,
  type,
  goalDate,
  goalDistance,
  shouldValidate,
}) => {
  const disabledStartDate = (value: Moment) => {
    const startDate = moment().subtract(1, "months").startOf("day");
    const endDate = moment().add(51, "weeks").startOf("day");
    return value < startDate || value > endDate;
  };
  
  const [isModalGoalVisible, setIsModalGoalVisible] = useState(false);
  const [isShowGoal, setShowGoal] = useState(false);

  const handleOk = () => {
    setIsModalGoalVisible(false);
    if (goalDate && goalDistance) {
      setShowGoal(true);
    }
  };

  const showGoalModal = () => {
    setIsModalGoalVisible(true);
  };

  const hideGoalModal = () => {
    setIsModalGoalVisible(false);
  };

  const disabledRaceDate = (value: Moment) => {
    const startDate = moment().add(22, "day").startOf("day");
    const endDate = moment().add(1, "year").startOf("day");
    return value < startDate || value > endDate;
  };

  const { t } = useTranslation();

  const validateGoal =
    shouldValidate &&
    (!getValidateStatus(StateKeys.GoalDistance) ||
    !getValidateStatus(StateKeys.GoalDate));

  const validateRaceDate = !getValidateStatus(StateKeys.RaceDate) && shouldValidate;
  const validateLongRunDay =
    !getValidateStatus(StateKeys.LongRunDay) && shouldValidate;
  const validateAvailability =
    !getValidateStatus(StateKeys.WeekAvailability) && shouldValidate;
    
  return (
    <Row>
      <Col md={6} xs={24} sm={24}>
        <Item label={t(translations.startDate)} className="race-item">
          <DatePicker
            className="w100"
            name="startDate"
            onChange={onChangeStartDate}
            disabledDate={disabledStartDate}
            placeholder={t(translations.selectStartDate)}
            defaultValue={moment()}
          />
        </Item>
      </Col>

      {type === 1 && (
        <Col md={6} xs={24} sm={24} className="my-perfomance-title">
          <Title level={5}>{t(translations.myGoalTitle)}</Title>
          {!isShowGoal && (
            <>
              <Button onClick={showGoalModal} type="primary">
                {t(translations.set)}
              </Button>
              <div className="hint-text">{t(translations.hints.myGoal)}</div>
            </>
          )}
          {validateGoal && <span className="validation-message">{t(translations.validationMessage)}</span>}
          {isShowGoal && (goalDate || goalDistance) && (
            <div className="my-perfomance-container">
              {t(translations.myGoal, {
                distance: goalDistance,
                date: goalDate,
              })}
              <Button type="link" onClick={showGoalModal}>
                {t(translations.edit)}
              </Button>
            </div>
          )}
          <GoalModal
            visible={isModalGoalVisible}
            handleOk={handleOk}
            handleCancel={hideGoalModal}
            handleGoalDistanceChange={onDistanceGoalChanged}
            handleGoalDateChange={onDateGoalChanged}
            disableDate={disabledRaceDate}
            type={type}
          />
        </Col>
      )}
      {type === 2 && (
        <Col md={6} xs={24} sm={24}>
          <Item
            label={t(translations.endDate)}
            className="race-item"
            rules={[
              {
                required: true,
              },
            ]}
            validateStatus={validateRaceDate ? 'error' : 'success'}
            help={validateRaceDate && t(translations.validationMessage)}
          >
            <DatePicker
              name="raceDate"
              disabledDate={disabledRaceDate}
              className="racedate-item w100"
              onChange={onChangeRaceDate}
              placeholder={t(translations.selectEndDate)}
            />
          </Item>
        </Col>
      )}
      <Col md={6} xs={24} sm={24}>
        <Item
          label={t(translations.weekAvailability)}
          className="race-item"
          validateStatus={validateAvailability ? "error" : "success"}
          help={validateAvailability && t(translations.validationMessage)}
        >
          <Select
            mode="multiple"
            allowClear
            className="w100"
            placeholder={t(translations.selectAvailability)}
            onChange={onChangeAvailability}
            maxTagTextLength={6}
            maxTagCount={2}
          >
            {days.map((item, key) => {
              return (
                <Option key={key} value={item.name}>
                  {t(item.i18key)}
                </Option>
              );
            })}
          </Select>
          <div className="hint-text">{t(translations.hints.weekAvailability)}</div>
        </Item>
      </Col>
      <Col md={6} xs={24} sm={24}>
        <Item
          label={t(translations.longRunDay)}
          className="race-item"
          validateStatus={validateLongRunDay ? "error" : "success"}
          help={validateLongRunDay && t(translations.validationMessage)}
        >
          <Select
            allowClear
            className="w100"
            placeholder={t(translations.selectLongRunDay)}
            onChange={onChangeLongDay}
            disabled={!selectedDays.length}
          >
            {mapSelectedDays(selectedDays).map(
              (item: SelectDays, key: number) => {
                return (
                  <Option key={key} value={item.name}>
                    {t(item.i18key)}
                  </Option>
                );
              }
            )}
          </Select>
          <div className="hint-text">{t(translations.hints.longRunDay)}</div>
        </Item>
      </Col>
    </Row>
  );
};

export default RaceItem;
