import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { ConvertedToObjectType } from "./locales/types"
import en from "./locales/en.json"
import ru from "./locales/ru.json"
import enAntLocale from "antd/lib/locale/en_US"
import ruAntLocale from "antd/lib/locale/ru_RU"

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

export const getAntLocale = (appLocale: string) => {
  switch (appLocale as SupportedLocale) {
    case "ru":
      return ruAntLocale
    case "en":
      return enAntLocale
    default:
      console.warn("Unsupported locale: " + appLocale)
      return enAntLocale
  }
}

const translationsJson = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
}

export type SupportedLocale = keyof typeof translationsJson
export const supportedLocales: SupportedLocale[] = Object.keys(
  translationsJson
) as SupportedLocale[]
const resources = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
}

const customNavigatorLanguageDetector = {
  name: "customNavigatorDetector",

  lookup() {
    const lang = navigator.language
    return lang.includes("-") ? lang.substring(0, lang.indexOf("-")) : lang
  },
}

const languageDetector = new LanguageDetector()
languageDetector.addDetector(customNavigatorLanguageDetector)

export type TranslationResource = typeof en
export const translations: ConvertedToObjectType<TranslationResource> =
  {} as any

const convertLanguageJsonToObject = (obj: any, dict: any, current?: string) => {
  Object.keys(obj).forEach((key) => {
    const currentLookupKey = current ? `${current}.${key}` : key
    if (typeof obj[key] === "object") {
      dict[key] = {}
      convertLanguageJsonToObject(obj[key], dict[key], currentLookupKey)
    } else {
      dict[key] = currentLookupKey
    }
  })
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(languageDetector)
  .init(
    {
      resources,
      detection: {
        order: ["localStorage", "customNavigatorDetector"],
        lookupLocalStorage: "i18nextLng",
        caches: ["localStorage"],
      },
      supportedLngs: Object.keys(translationsJson),
      cleanCode: true,
      load: "languageOnly",
      nonExplicitSupportedLngs: true,
      fallbackLng: "en",
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    },
    () => {
      convertLanguageJsonToObject(en, translations)
    }
  )
export default i18n
