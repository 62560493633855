import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { translations } from "../../i18n";
import { Button, Row, Col, Typography, Form, Switch, InputNumber } from "antd";
import { Moment } from "moment";
import PerfomanceModal from "./PerfomanceModal";
import { StateKeys } from "../../types";

const { Title } = Typography;
const { Item } = Form;

interface Props {
  distance: string | number;
  time: string | number;
  strictNumberOfDays: number;
  onDistanceChanged: (value: string) => void;
  onTimeChanged: (date: Moment | null, dateString: string) => void;
  onChangeDays: (value: number) => void;
  onChangeVolume: (value: number) => void;
  onChangeLongRun: (value: number) => void;
  onChangeHeartRate: (value: number) => void;
  onChangeBegin: (value: boolean) => void;
  getValidateStatus: (name: StateKeys) => boolean;
  type: number | string;
  shouldValidate: boolean;
}

const PerfomanceItem: React.FC<Props> = ({
  distance,
  time,
  strictNumberOfDays,
  onDistanceChanged,
  onTimeChanged,
  onChangeDays,
  onChangeVolume,
  onChangeLongRun,
  onChangeHeartRate,
  onChangeBegin,
  getValidateStatus,
  shouldValidate,
  type,
}) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isShowPerfomance, setShowPerfomance] = useState(false);
  const [checkedDays, setCheckedDays] = useState(true);

  const onChangeNumberOfDays = (checked: boolean) => {
    setCheckedDays(checked);
    const sndValue = checked ? 0 : 1;
    onChangeDays(sndValue);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    if (distance && time) {
      setShowPerfomance(true);
    }
  };

  const [longRun, setLongRun] = useState(true);
  const onChangeSwitcher = (checked: boolean) => {
    setLongRun(checked);
  };

  const validatePerfomance = (!getValidateStatus(StateKeys.Distance) || !getValidateStatus(StateKeys.Time) || !getValidateStatus(StateKeys.MaxHeartRate)) && shouldValidate;

  return (
    <Row>
      <Col md={6} xs={24} sm={24} className="my-perfomance-title">
        <Title level={5}>{t(translations.myPerfomanceTitle)}</Title>

        {!isShowPerfomance && (
          <>
            <Button onClick={showModal} type="primary">
              {t(translations.set)}
            </Button>
            <div className="hint-text">{t(translations.hints.myPerformance)}</div>
          </>
        )}
        {validatePerfomance && (
            <span className="validation-message">{t(translations.validationMessage)}</span>
          )}
        {isShowPerfomance && (distance || time) && (
          <div className="my-perfomance-container">
            {t(translations.myPerfomance, {
              distance: distance,
              time: time,
            })}
            <Button type="link" onClick={showModal}>
              {t(translations.edit)}
            </Button>
          </div>
        )}
        <PerfomanceModal
          visible={isModalVisible}
          handleOk={handleOk}
          handleCancel={hideModal}
          handleDistanceChange={onDistanceChanged}
          handleTimeChange={onTimeChanged}
          handleChangeHeartRate={onChangeHeartRate}
        />
      </Col>

      <Col md={6} xs={24}>
        <Item
          label={t(translations.startingWeekVolume)}
          className="volume-item"
        >
          <InputNumber
            className="w100"
            addonAfter={t(translations.km)}
            defaultValue={10}
            min={10}
            max={100}
            onChange={onChangeVolume}
          />
          <div className="hint-text">{t(translations.hints.startingWeekVolume)}</div>
        </Item>
      </Col>

      <Col md={6} xs={24} sm={24}>
        {type !== 1 ? (
          <Row className="switch">
            <Col span={18}>
              <Item label={t(translations.numberOfDays)}>
                <Switch
                  defaultChecked
                  checked={checkedDays}
                  checkedChildren={t(translations.switchAuto)}
                  unCheckedChildren={t(translations.switchManual)}
                  onChange={onChangeNumberOfDays}
                />
              </Item>
            </Col>
            <Col
              span={6}
              className="switch-value-container switch-checked-days"
            >
              {!checkedDays && (
                <Item>
                  <InputNumber
                    min={1}
                    max={10}
                    value={strictNumberOfDays}
                    onChange={onChangeDays}
                    className="switch-input"
                  />
                </Item>
              )}
            </Col>
          </Row>
        ) : null}
      </Col>
      {type === 2 && (
        <Col md={6} xs={24}>
          <Item label={t(translations.beginEasy)}>
            <Switch defaultChecked onChange={onChangeBegin} />
          </Item>
        </Col>
      )}
    </Row>
  );
};

export default PerfomanceItem;
