import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Button, notification } from "antd";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/en-gb";
import localeEN from "antd/es/locale/en_GB";
import localeRU from "antd/es/locale/ru_RU";
import { NotificationPlacement } from "antd/lib/notification";

import { getPreview, getCheckout } from "./components/Form/api";
import { formatWeeks, validateSubmit } from "./utils";
import { MapWeeks, State, LoadingStatus } from "./types";
import { translations } from "./i18n";
import SuccessPage from "./pages/SuccessPage";
import ErrorPage from "./pages/ErrorPage";
import MainPage from "./pages/MainPage";
import ResultPage from "./pages/ResultPage";

import "./index.scss";

const openCookieQuestion = (
  placement: NotificationPlacement,
  setAgreeToStorage: () => void,
  title: string,
  text: string,
  button: string
) => {
  const key = `open${Date.now()}`;
  const handleClose = () => {
    setAgreeToStorage();
    notification.close(key);
  };
  const btn = (
    <Button type="primary" size="small" onClick={handleClose}>
      {button}
    </Button>
  );
  notification.open({
    message: title,
    description: (
      <span
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      ></span>
    ),
    btn,
    key,
    placement: placement,
    duration: 0,
    closeIcon: <div></div>,
  });
};

moment.locale("en-gb", {
  week: {
    dow: 1, /// Date offset
  },
});

const App = () => {
  const { t } = useTranslation();
  const [weeks, setWeeks] = useState<MapWeeks>([]);
  const [planCode, setPlanCode] = useState("");
  const [session, setSession] = useState(null);
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [loadStatus, setLoadStatus] = useState<LoadingStatus>(
    LoadingStatus.None
  );
  const [shouldValidate, setValidate] = useState(false);
  const locale = i18next.language === "ru" ? localeRU : localeEN;

  const setAgreeToStorage = () => {
    localStorage.setItem("omy-cookie", JSON.stringify(true));
  };

  useEffect(() => {
    if (session) {
      stripe?.redirectToCheckout({
        sessionId: session,
      });
    }
  }, [session, stripe]);

  useEffect(() => {
    (async () => {
      const s = await loadStripe(process.env.REACT_APP_STRIPE_KEY as string);
      setStripe(s);
    })();
  }, []);

  useEffect(() => {
    const isAgreeWithCookie = localStorage.getItem("omy-cookie");
    if (!isAgreeWithCookie) {
      openCookieQuestion(
        "bottomLeft",
        setAgreeToStorage,
        t(translations.cookieTitle),
        t(translations.cookieText),
        t(translations.cookieAgree)
      );
    }
  }, [t]);

  const handleSubmit = async (data: State) => {
    const isValid = validateSubmit(data);
    if (isValid) {
      setLoadStatus(LoadingStatus.Pending);
      const response = await getPreview({ ...data, lang: i18next.language });
      if (response.status === 200) {
        setLoadStatus(LoadingStatus.Success);
        setPlanCode(response.data.planCode);
        setWeeks(formatWeeks(response.data.weeks));
      }
    } else {
      setValidate(true);
    }
  };

  const handleCheckout = async () => {
    const response = await getCheckout(planCode);
    if (response.status === 200) {
      setSession(response.data.sessionId);
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <MainPage
              locale={locale}
              handleSubmit={handleSubmit}
              shouldValidate={shouldValidate}
              loadStatus={loadStatus}
              weeks={weeks}
              handleCheckout={handleCheckout}
            />
          }
        />
        <Route path="/result/:id" element={<ResultPage />} />
        <Route path="/success/:id" element={<SuccessPage />} />
        <Route path="/error/:id" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
