import React from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { Moment } from "moment"
import { translations } from "../../i18n"
import { Form, Button, Select, Row, Col, Modal, TimePicker,InputNumber } from "antd"
const { Item } = Form
const { Option } = Select

interface Props {
  visible: boolean
  handleOk: () => void
  handleCancel: () => void
  handleDistanceChange: (value: string) => void
  handleTimeChange: (date: Moment | null, dateString: string) => void
  handleChangeHeartRate: (value: number) => void
}

const PerfomanceModal: React.FC<Props> = ({
  visible,
  handleOk,
  handleCancel,
  handleDistanceChange,
  handleTimeChange,
  handleChangeHeartRate
}) => {
  const { t } = useTranslation()
  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className='perfomance-modal'
    >
      <Row>
        <Col xs={24} md={6} className='perfomance-item'>
          <Item label={t(translations.distance.title)} labelCol={{ span: 24 }}>
            <Select
              allowClear
              size="large"
              placeholder={t(translations.perfomanceModal.selectDistance)}
              onChange={handleDistanceChange}
            >
              <Option value={5}>
                {t(translations.perfomanceModal["5km"])}
              </Option>
              <Option value={10}>
                {t(translations.perfomanceModal["10km"])}
              </Option>
              <Option value={21}>
                {t(translations.perfomanceModal.halfMarathon)}
              </Option>
              <Option value={42}>
                {t(translations.perfomanceModal.marathon)}
              </Option>
            </Select>
          </Item>
        </Col>
        <Col md={6} xs={24} className='perfomance-item'>
          <Item label={t(translations.time.title)} labelCol={{ span: 24 }}>
            <TimePicker
              placeholder={t(translations.perfomanceModal.selectTime)}
              defaultValue={moment("00:00:00", "HH:mm:ss")}
              size="large"
              onChange={handleTimeChange}
              showNow={false}
            />
          </Item>
        </Col>

        <Col md={6} xs={24} className='perfomance-item'>
        <Item label={t(translations.maxHeartRate)} labelCol={{ span: 24 }} className="heartrate-item"> 
          <InputNumber
            min={150}
            max={220}
            size='large'
            defaultValue={190}
            className="w100"
            onChange={handleChangeHeartRate}
          />
        </Item>
      </Col>
        <Col md={3} xs={24} className="my-perfomance-ok ">
          <Button key="1" onClick={handleOk} size="large" type="primary">
            {t(translations.perfomanceModal.ok)}
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default PerfomanceModal
