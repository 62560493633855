import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { translations } from "../../i18n"
import { Radio, Space } from "antd"
const { Group } = Radio

interface Props {
  onChangeType: (e: any) => void
  type: number | string
}

const TypeItem: React.FC<Props> = ({ onChangeType, type }) => {
  const [screenWidth, setScreenWidth] = useState(window.screen.width)

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const { t } = useTranslation()
  return (
    <Group onChange={onChangeType} name="type" value={type} className="type">
      <Space direction={screenWidth > 768 ? "horizontal" : "vertical"}>
        <Radio value={1}>{t(translations.type.prepareForRace)}</Radio>
        <Radio value={2}>{t(translations.type.getFit)}</Radio>
      </Space>
    </Group>
  )
}

export default TypeItem
