import React from "react";
import { Button } from "antd";
import TrainingDay from "./TrainingDay";
import { useTranslation } from "react-i18next";
import { translations } from "../../i18n";
import { days } from "../../utils";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { MapWeeks, MapWeek, TrainingDay as Day } from "../../types";
import "./style.scss";
import moment from "moment";

interface Props {
  weeks?: MapWeeks;
  handleCheckout: () => Promise<void>;
}

const Plan: React.FC<Props> = ({ weeks, handleCheckout }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="training-table-header">
        <div>
          <h3 className="training-table-title">{t(translations.planTitle)}</h3>
          <p className="training-table-description">
            {t(translations.planDescription)}
          </p>
        </div>
        <div className="training-buy-container">
          <Button type="primary" size="large" onClick={handleCheckout} className="training-buy" danger>
            {t(translations.buy)}
          </Button>
        </div>
      </div>

      {
        weeks?.map((item: MapWeek) => {
          return (
            <div className="table-block" key={item.number}>
              <div className="training-table-header">
                <div>
                  <h5 className="training-table-title">{item.number === 1 ? t(translations.tableHeaders.firstWeekTitle) : t(translations.tableHeaders.secondWeekTitle)}</h5>
                  <p className="training-table-description">
                    {item.number === 1 ? t(translations.tableHeaders.firstWeekDescription) : t(translations.tableHeaders.secondWeekDescription)}
                  </p>
                </div>
              </div>
              <Table className="training-table">
                <Thead className="training-header">
                  <Tr>
                    <Th>{t(translations.days.week)}</Th>
                    {days.map((item, key) => {
                      return <Th key={key}>{t(item.i18key)}</Th>;
                    })}
                  </Tr>
                </Thead>
                <Tbody className="training-content">
                  <Tr>
                    <Td>
                      {t(translations.weekStartAt)}: {moment(item.weekMonday).format("YYYY-MM-DD")} <br />
                      {t(translations.weekType)}: {item.type}
                      <br />
                      {t(translations.totalVolume, {
                        value: Number(item.fact) / 1000,
                      })}
                    </Td>
                    <Td>
                      <TrainingDay
                        trainings={item.trainings?.find(
                          (i: Day) => i.dayOfWeek === 1
                        )}
                      />
                    </Td>

                    <Td>
                      <TrainingDay
                        trainings={item.trainings?.find(
                          (i: Day) => i.dayOfWeek === 2
                        )}
                      />
                    </Td>

                    <Td>
                      <TrainingDay
                        trainings={item.trainings?.find(
                          (i: Day) => i.dayOfWeek === 3
                        )}
                      />
                    </Td>

                    <Td>
                      <TrainingDay
                        trainings={item.trainings?.find(
                          (i: Day) => i.dayOfWeek === 4
                        )}
                      />
                    </Td>

                    <Td>
                      <TrainingDay
                        trainings={item.trainings?.find(
                          (i: Day) => i.dayOfWeek === 5
                        )}
                      />
                    </Td>

                    <Td>
                      <TrainingDay
                        trainings={item.trainings?.find(
                          (i: Day) => i.dayOfWeek === 6
                        )}
                      />
                    </Td>

                    <Td>
                      <TrainingDay
                        trainings={item.trainings?.find(
                          (i: Day) => i.dayOfWeek === 0
                        )}
                      />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </div>
          )
        })
      }

      <div className="plan-description-bottom align-center">
        {t(translations.planDescriptionBottom)}
      </div>

      <div className="training-buy-container align-center">
        <Button type="primary" size="large" className="training-buy" danger onClick={handleCheckout}>
          {t(translations.buy)}
        </Button>
      </div>

      <div className="contact-us">
        {t(translations.contactUs)}&nbsp;<a href={`mailto:${t(translations.supportEmail)}`}>{t(translations.supportEmail)}</a>
      </div>
    </>
  );
};

export default Plan;
