import React from "react"
import { Form, Button, Row, Col } from "antd"
import { useTranslation } from "react-i18next"
import { translations } from "../../i18n"

const { Item } = Form

interface Props {
  onSubmit: any
}

const SubmitItem: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation()
  return (
    <Row>
      <Col md={6} xs={24} sm={24}>
        <Item>
          <Button
            // onClick={onSubmit}
            type="primary"
            size="large"
            htmlType="submit"
            className="w100"
          >
            {t(translations.submit)}
          </Button>
        </Item>
      </Col>
    </Row>
  )
}

export default SubmitItem
