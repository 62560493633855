import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { translations } from "../../i18n"
import { Moment } from "moment"
import { Form, Typography } from "antd"
import { initialState, validateField } from "../../utils"
import { StateKeys, State } from "../../types"
import TypeItem from "./TypeItem"
import SubmitItem from "./SubmitItem"
import RaceItem from "./RaceItem"
import PerfomanceItem from "./PerfomanceItem"
import './style.scss';

const { Title } = Typography

interface Props {
  handleSubmit: (data: any) => void;
  shouldValidate: boolean;
}

const MainForm: React.FC<Props> = ({ handleSubmit, shouldValidate }) => {
  const { t } = useTranslation()
  const [data, setData] = useState<State>(initialState)

  const getValidateStatus = (name:StateKeys) => {
    return data[name].validate
  }

  const onChangeBegin = (checked: boolean) => {
    setData({
      ...data,
      [StateKeys.Begin]: {
        ...data[StateKeys.Begin],
        value: checked,
      }
    })
  }

  const onChangeValue = (value: string | number, name: StateKeys) => {
    setData({
      ...data,
      [name]: {
        ...data[name],
        validate: validateField(name, value),
        value: value,
      },
    })
  }

  const onChangeDate = (
    date: Moment | null,
    dateString: string,
    name: StateKeys
  ) => {
    setData({
      ...data,
      [name]: {
        ...data[name],
        validate: validateField(name, dateString),
        value: dateString,
      },
    })
  }

  const onChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const name: StateKeys = e.target.name as StateKeys

    setData({
      ...data,
      [name]: {
        ...data[name],
        validate: validateField(name, value),
        value: value,
      },
    })
  }

  return (
    <>
      <Title level={3}>{t(translations.goalQuestion)}</Title>
      <Form
        name="basic"
        layout="vertical"
        onFinish={() => handleSubmit(data)}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        <TypeItem onChangeType={onChangeData} type={data.type.value} />

        <RaceItem
          goalDate={data.goalDate.value}
          goalDistance={data.goalDistance.value}
          getValidateStatus={getValidateStatus}
          shouldValidate={shouldValidate}
          onChangeStartDate={(...rest) => {
            onChangeDate(...rest, StateKeys.StartDate)
          }}
          onChangeRaceDate={(...rest) => {
            onChangeDate(...rest, StateKeys.RaceDate)
          }}
          onChangeAvailability={(value: string) => {
            onChangeValue(value, StateKeys.WeekAvailability)
          }}
          onChangeLongDay={(value: string) => {
            onChangeValue(value, StateKeys.LongRunDay)
          }}
          onDistanceGoalChanged={(value: string) => {
            onChangeValue(value, StateKeys.GoalDistance)
          }}
          onDateGoalChanged={(...rest) => {
            onChangeDate(...rest, StateKeys.GoalDate)
          }}
          selectedDays={data.weekAvailability.value}
          type={data.type.value}
        />

        <PerfomanceItem
          distance={data.distance.value}
          time={data.time.value}
          strictNumberOfDays={data.strictNumberOfDays.value}
          getValidateStatus={getValidateStatus}
          shouldValidate={shouldValidate}
          onDistanceChanged={(value: string) => {
            onChangeValue(value, StateKeys.Distance)
          }}
          onTimeChanged={(...rest) => {
            onChangeDate(...rest, StateKeys.Time)
          }}
          onChangeDays={(value: number) => {
            onChangeValue(value, StateKeys.StrictNumberOfDays)
          }}
          onChangeVolume={(value: number) => {
            onChangeValue(value, StateKeys.Volume)
          }}
          onChangeLongRun={(value: number) => {
            onChangeValue(value, StateKeys.LongRun)
          }}
          onChangeHeartRate={(value: number) => {
            onChangeValue(value, StateKeys.MaxHeartRate)
          }}
          onChangeBegin={onChangeBegin}
          type={data.type.value}
        />

        <SubmitItem onSubmit={() => handleSubmit(data)} />
      </Form>
    </>
  )
}

export default MainForm
