import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { translations } from "../i18n";
import { provisionPayment } from "../components/Form/api";

const SuccessPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [planCode, setPlanCode] = useState("");
  const [session, setSession] = useState("");
  
  useEffect(() => {
    const paramsString = document.location.search;
    const searchParams = new URLSearchParams(paramsString);
    const session_id = searchParams.get("session_id");
    if (session_id) {
      setSession(session_id);
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      if (session) {
        const response = await provisionPayment(session);
        if (response.status === 200) {
          setPlanCode(response.data.planCode);
        }
      }
    })();
  }, [session]);

  return (
    <div className="success-container">
      <CheckCircleTwoTone twoToneColor="#52c41a" />
      <h3>{t(translations.paymentSuccessTitle)}</h3>
      <Link to={planCode ? `/result/${planCode}` : "#"}>
        <Button disabled={!planCode} size="large" type="primary">
          {t(translations.getPlan)}
        </Button>
      </Link>
    </div>
  );
};

export default SuccessPage;
