import { translations } from "./i18n"
import moment from "moment"
import { Days, Day, StateKeys, Weeks, Week } from "./types"

export const formatObjToLowerCase = (obj: any) => {
  Object.keys(obj).forEach(function (key) {
    var k = key.toLowerCase();
    if (k !== key) {
      obj[k] = obj[key];
      delete obj[key];
    }
  });
  return (obj);
}

export const formatDaysToNumber = (day: Days) => {
  switch (day) {
    case Days.Monday: return 1;
    case Days.Tuesday: return 2;
    case Days.Wednesday: return 3;
    case Days.Thursday: return 4;
    case Days.Friday: return 5;
    case Days.Saturday: return 6;
    case Days.Sunday: return 0;
  }
}

export const validateSubmit = (data: any) => {
  const validateGoal = data.goalDistance.validate && data.goalDate.validate;
  const validatePerfomance = data.distance.validate && data.time.validate && data.maxHeartRate.validate;
  const validateRaceDate = data.raceDate.validate;
  const validateDays = data.weekAvailability.validate && data.longRunDay.validate;

  if (data.type.value === 1) {
    if (validateGoal && validatePerfomance && validateDays) {
      return true;
    }
    else {
      return false
    }
  }
  else {
    if (validatePerfomance && validateRaceDate && validateDays) {
      return true
    }
    else {
      return false
    }
  }
}

export const formatWeeks = (weeks: Weeks) => {
  return weeks.map((item: Week) => {
    return {
      number: item.weekNumber,
      type: item.weekType,
      fact: item.factWeeklyVolumeMeters,
      trainings: item.content,
      weekMonday: item.weekMonday
    }
  })
}

export const validateField = (name: StateKeys, value: string | number) => {
  switch (name) {
    case StateKeys.GoalDate:
      return !!value;
    case StateKeys.RaceDate:
      return !!value;
    case StateKeys.GoalDistance:
      return !!value
    case StateKeys.Time:
      return !!value
    case StateKeys.Distance:
      return !!value
    case StateKeys.MaxHeartRate:
      return !!value
    case StateKeys.WeekAvailability:
      return !!value
    case StateKeys.LongRunDay:
      return !!value
  }
}

export const mapSelectedDays = (days: Array<Day> | undefined) => {
  if (days) {
    return days?.map((item: Day) => {
      return {
        name: objDays[item].name,
        i18key: objDays[item].i18key,
      }
    })
  } else {
    return []
  }
}

export const objDays = {
  [Days.Monday]: {
    name: Days.Monday,
    i18key: translations.days.monday,
  },
  [Days.Tuesday]: {
    name: Days.Tuesday,
    i18key: translations.days.tuesday,
  },
  [Days.Wednesday]: {
    name: Days.Wednesday,
    i18key: translations.days.wednesday,
  },
  [Days.Thursday]: {
    name: Days.Thursday,
    i18key: translations.days.thursday,
  },
  [Days.Friday]: {
    name: Days.Friday,
    i18key: translations.days.friday,
  },
  [Days.Saturday]: {
    name: Days.Saturday,
    i18key: translations.days.saturday,
  },
  [Days.Sunday]: {
    name: Days.Sunday,
    i18key: translations.days.sunday,
  },
}

export const days = [
  { name: Days.Monday, i18key: translations.days.monday },
  { name: Days.Tuesday, i18key: translations.days.tuesday },
  { name: Days.Wednesday, i18key: translations.days.wednesday },
  { name: Days.Thursday, i18key: translations.days.thursday },
  { name: Days.Friday, i18key: translations.days.friday },
  { name: Days.Saturday, i18key: translations.days.saturday },
  { name: Days.Sunday, i18key: translations.days.sunday },
]

export const initialState = {
  [StateKeys.Begin]: {
    value: true,
    validate: false,
    message: ""
  },
  [StateKeys.Type]: {
    value: 1,
    validate: false,
    message: "",
  },
  [StateKeys.StartDate]: {
    value: moment().format(),
    validate: false,
    message: "",
  },
  [StateKeys.RaceDate]: {
    value: "",
    validate: false,
    message: "",
  },
  [StateKeys.WeekAvailability]: {
    value: "",
    validate: false,
    message: "",
  },
  [StateKeys.LongRunDay]: {
    value: "",
    validate: false,
    message: "",
  },
  [StateKeys.StrictNumberOfDays]: {
    value: 0,
    validate: true,
    message: "",
  },
  [StateKeys.Time]: {
    value: "",
    validate: false,
    message: "",
  },
  [StateKeys.Distance]: {
    value: "",
    validate: false,
    message: "",
  },
  [StateKeys.Volume]: {
    value: 10,
    validate: false,
    message: "",
  },
  [StateKeys.LongRun]: {
    value: 0,
    validate: false,
    message: "",
  },
  [StateKeys.MaxHeartRate]: {
    value: 190,
    validate: true,
    message: "",
  },
  [StateKeys.QualityWorkoutsNumber]: {
    value: "",
    validate: false,
    message: "",
  },
  [StateKeys.GoalDate]: {
    value: "",
    validate: false,
    message: ""
  },
  [StateKeys.GoalDistance]: {
    value: "",
    validate: false,
    message: ""
  }
}
