import React from "react";
import TrainingDay from "../Plan/TrainingDay";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { translations } from "../../i18n";
import { days } from "../../utils";
import { useTranslation } from "react-i18next";
import { MapWeeks, MapWeek, TrainingDay as Day } from "../../types";
import moment from "moment";

interface Props {
    weeks?: MapWeeks;
  }

const ResultPlan:React.FC<Props> = ({ weeks }) => {
    const { t } = useTranslation();
  return (
    <Table className="training-table">
      <Thead className="training-header">
        <Tr>
          <Th>{t(translations.days.week)}</Th>
          {days.map((item, key) => {
            return <Th key={key}>{t(item.i18key)}</Th>;
          })}
        </Tr>
      </Thead>
      <Tbody className="training-content">
        {weeks?.map((item: MapWeek) => {
          return (
            <Tr key={item.number}>
              <Td>
                {t(translations.weekStartAt)}: {moment(item.weekMonday).format("YYYY-MM-DD")} <br />
                {t(translations.weekType)}: {item.type}
                <br />
                {t(translations.totalVolume, {
                  value: Number(item.fact) / 1000,
                })}
              </Td>
              <Td>
                <TrainingDay
                  trainings={item.trainings?.find(
                    (i: Day) => i.dayOfWeek === 1
                  )}
                />
              </Td>

              <Td>
                <TrainingDay
                  trainings={item.trainings?.find(
                    (i: Day) => i.dayOfWeek === 2
                  )}
                />
              </Td>

              <Td>
                <TrainingDay
                  trainings={item.trainings?.find(
                    (i: Day) => i.dayOfWeek === 3
                  )}
                />
              </Td>

              <Td>
                <TrainingDay
                  trainings={item.trainings?.find(
                    (i: Day) => i.dayOfWeek === 4
                  )}
                />
              </Td>

              <Td>
                <TrainingDay
                  trainings={item.trainings?.find(
                    (i: Day) => i.dayOfWeek === 5
                  )}
                />
              </Td>

              <Td>
                <TrainingDay
                  trainings={item.trainings?.find(
                    (i: Day) => i.dayOfWeek === 6
                  )}
                />
              </Td>

              <Td>
                <TrainingDay
                  trainings={item.trainings?.find(
                    (i: Day) => i.dayOfWeek === 0
                  )}
                />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default ResultPlan;
