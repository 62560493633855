export enum StateKeys {
  Begin = "begin",
  Type = "type",
  StartDate = "startDate",
  RaceDate = "raceDate",
  WeekAvailability = "weekAvailability",
  LongRunDay = "longRunDay",
  StrictNumberOfDays = "strictNumberOfDays",
  Time = "time",
  Distance = "distance",
  Volume = "volume",
  LongRun = "longRun",
  MaxHeartRate = "maxHeartRate",
  QualityWorkoutsNumber = "qualityWorkoutsNumber",
  GoalDistance = 'goalDistance',
  GoalDate = 'goalDate',
}

export enum Days {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}

export type Day = keyof typeof Days;

export interface SelectDays {
  name: Day;
  i18key: string;
}

export interface StateItem {
  value: any;
  validate: boolean
  message: string
}

export type State = {
  [key in StateKeys]: StateItem
}
export type Weeks = Array<Week>

export interface Week {
  weekNumber?: number;
  weekMonday: Date,
  weekType?: string;
  factWeeklyVolumeMeters?: number;
  content?: Trainings;
}

export type MapWeeks = Array<MapWeek>;

export interface MapWeek {
  number?: number,
  weekMonday: Date,
  type?: string,
  fact?: number
  trainings?: Trainings;
}

export type Trainings = Array<TrainingDay>

export interface TrainingDay {
  dayOfWeek?: number;
  workouts?: Workouts
}

export type Workouts = Array<Workout>

export interface Workout {
  description?: string;
  links?: Array<string>;
  totalDistanceMeters?: number;
  workoutType?: string;
}

export enum LoadingStatus {
  None = 'None',
  Pending = 'Pending',
  Success = "Success",
  Error = "Error"
}