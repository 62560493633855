import axios from "axios";
import moment from "moment";
import { Days } from "../../types";
import { formatObjToLowerCase, formatDaysToNumber } from "../../utils";

export const getPreview = (data: any) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/plan-api/v1/preview`,
    mapData(data)
  );
};

export const provisionPayment = (sessionId: string) => {
  return axios.put(
    `${process.env.REACT_APP_BASE_URL}/plan-api/v1/checkout/${sessionId}`
  );
};

export const getCheckout = (planCode: string) => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/plan-api/v1/checkout`, {
    planCode: planCode,
    redirectUri: `${process.env.REACT_APP_STAGE}/success/${planCode}`,
    cancelRedirectUri: `${process.env.REACT_APP_STAGE}/error/${planCode}`,
  });
};

export const getPlan = (planCode: string) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/plan-api/v1/plan/${planCode}`
  );
};

export const formatAvailableDays = (days: Array<Days>) => {
  let data = {};
  for (let i = 0; i < days.length; i++) {
    data = {
      ...data,
      [days[i]]: true,
    };
  }
  return data;
};

export const mapData = (data: any) => {
  return {
    startDate: moment(data.startDate.value).format(),
    endDate:
      data.type.value === 1
        ? moment(data.goalDate.value).format()
        : moment(data.raceDate.value).format(),
    goalDistanceKm: data.type.value === 1 ? data.goalDistance.value : 0,
    language: data.lang,
    beginEasy: data.type.value === 1 ? false : data.begin.value,
    isRacePlan: data.type.value === 1 ? true : false,
    runningData: {
      runPerformanceDistanceKm: data.distance.value,
      runPerformanceTime: data.time?.value,
      startingWeekVolumeMeters: data.volume.value * 1000,
      startingLongRunMeters: data.longRun.value
        ? data.longRun.value * 1000
        : null,
      maxHeartRate: data.maxHeartRate.value,
      weekAvailability: {
        longWorkoutDay: formatDaysToNumber(data.longRunDay.value),
        strictNumberOfDays:
          data.type.value !== 1 ? data.strictNumberOfDays.value : 0,
        availabilityDays: {
          ...formatObjToLowerCase(
            formatAvailableDays(data.weekAvailability.value)
          ),
        },
      },
    },
  };
};

// const req = {
//   beginEasy: true,
//   endDate: "2022-01-31T00:00:00+03:00",
//   startDate: "2021-12-29T18:34:00+03:00",
//   goalDistanceKm: 10,
//   isRacePlan: true,
//   language: "en",
//   runningData: {
//     runPerformanceDistanceKm: 10,
//     runPerformanceTime: "01:00:00",
//     startingWeekVolumeMeters: 30,
//     maxHeartRate: 190,
//     startingLongRunMeters: 6000,
//     weekAvailability: {
//       strictNumberOfDays: 0,
//       longWorkoutDay: 2,
//       availabilityDays: {
//         tuesday: true,
//         monday: true,
//       },
//     },
//   },
// };
