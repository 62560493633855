import React from "react"
import { TrainingDay as Day, Workout } from '../../types'
import { t } from "i18next"
import { translations } from "../../i18n"
import './style.scss'

interface Props {
  trainings?: Day
}

const TrainingDay: React.FC<Props> = ({ trainings }) => {
  if (!trainings) {
    return <h5 className="training-rest">{t(translations.rest)}</h5>
  }
  return (
    <>
      {trainings.workouts?.map((item: Workout, index: number) => {
        return (
          <div key={index}>
            <h5 className="training-title">{item.workoutType}</h5>
            <p className="training-details">{item.description}</p>
            <div className="training-links">
              {item.links?.map((i: string, index: number) => {
                return (
                  <a href={i} key={i + index}>{t(translations.videoExercises)} #{index + 1}</a>
                )
              })}
            </div>
          </div>
        )
      })}
    </>
  )
}

export default TrainingDay
