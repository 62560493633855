import React from "react";
import cn from 'classnames';
import { Row, Col, Spin, ConfigProvider } from "antd";
import { State, LoadingStatus } from "../types";
import Footer from "../components/Footer/Footer";
import MainForm from "../components/Form/MainForm";
import Plan from "../components/Plan/Plan";

interface Props {
    locale: any;
    handleSubmit: (data: State) => Promise<void>;
    handleCheckout: () => Promise<void>;
    shouldValidate: boolean;
    loadStatus: LoadingStatus
    weeks: any;
}

const MainPage:React.FC<Props> = ({ locale, handleSubmit, handleCheckout, shouldValidate, loadStatus, weeks}) => {
  return (
    <ConfigProvider locale={locale}>
      <Row className="main-content">
        <Col span={22} offset={1}>
          <MainForm
            handleSubmit={handleSubmit}
            shouldValidate={shouldValidate}
          />
          {loadStatus === LoadingStatus.Pending && (
            <div className="loader">
              <Spin size="large" />
            </div>
          )}
          {loadStatus === LoadingStatus.Success && <Plan handleCheckout={handleCheckout} weeks={weeks} />}
        </Col>
      </Row>
      <Row
        className={cn({
          "footer-absolute": loadStatus !== LoadingStatus.Success,
          "footer-relative": loadStatus === LoadingStatus.Success,
        })}
      >
        <Col span={24}>
          <Footer />
        </Col>
      </Row>
    </ConfigProvider>
  );
};

export default MainPage;
