import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <span>
        © All rights reserved. OMY! SportTech UG (haftungsbeshränkt), Berlin
        (HRB 212793B)
      </span>
      <span>
        By taking part in any of OMY! SportTech's surveys and filling into any
        of OMY!
      </span>
      <span>
        SportTech's forms you agree to <a target="_blank" rel="noreferrer" href="https://omysports.ai/privacy-policy">Privacy policy</a> and{" "}
        <a target="_blank" rel="noreferrer" href="https://omysports.ai/terms">Terms of Use </a>
      </span>
    </div>
  );
};

export default Footer;
