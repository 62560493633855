import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import "antd/dist/antd.css"
import "./index.scss"
import "./i18n"
import moment from 'moment';
import 'moment/locale/ru'
import { ConfigProvider } from "antd"
import { useTranslation } from "react-i18next"
import { getAntLocale } from "./i18n"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

const ConnectedApp = () => {
  const { i18n } = useTranslation()
  useEffect(() => {
    moment.locale(i18n.language)
  },[i18n.language])
  
  return (
      <ConfigProvider locale={getAntLocale(i18n.language)}>
        <App />
      </ConfigProvider>
  )
}

ReactDOM.render(<ConnectedApp />, document.getElementById("root"))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
