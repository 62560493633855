import React from "react";
import { Row, Col, Typography, Button } from "antd";
import { useNavigate } from 'react-router-dom';
import { translations } from "../../i18n";
import { useTranslation } from "react-i18next";
import { MapWeeks } from "../../types";
import ResultPlan from "./ResultPlan";
import { formatAvailableDays, formatLongRunDay } from "./utils";
import moment from "moment";
import "./style.scss";

const { Title } = Typography;

interface Props {
  id?: string;
  weeks?: MapWeeks;
  parameters: any;
}

const Result: React.FC<Props> = ({ id, weeks, parameters }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/')
  }
  return (
    <>
      <Row className="result-wrapper">
        <Col span={22} offset={1}>
          <Title level={3}>
            {parameters.isRacePlan
              ? t(translations.resultTraining, {
                  distance: parameters?.goalDistanceKm / 1000,
                })
              : t(translations.resultFitness, {
                  distance: parameters?.runningData?.runPerformanceDistanceKm,
                })}
          </Title>
          <div className="result-form-item">
            <span>
              {t(translations.startDate)}:{" "}
              {moment(parameters.startDate).format("DD.MM.YYYY")}
            </span>
          </div>

          {/* if not race */}
          {!parameters.isRacePlan && (
            <div className="result-form-item">
              <span>
                {t(translations.endDateResult)}:{" "}
                {moment(parameters.endDate).format("DD.MM.YYYY")}
              </span>
            </div>
          )}

          {/* if race */}
          {parameters.isRacePlan && (
            <div className="result-form-item">
              <span>
                {t(translations.myGoal, {
                  distance: parameters?.goalDistanceKm / 1000,
                  date: moment(parameters.endDate).format("DD.MM.YYYY"),
                })}
              </span>
            </div>
          )}

          <div className="result-form-item">
            {t(translations.contactUs)}&nbsp;<a href={`mailto:${t(translations.supportEmail)}`}>{t(translations.supportEmail)}</a>
          </div>
        </Col>
        <Col span={22} offset={1} className="result-page-button">
        </Col>
        <Col span={22} offset={1} className="result-plan-table">
          <ResultPlan weeks={weeks} />
        </Col>
      </Row>
    </>
  );
};

export default Result;
