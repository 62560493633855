import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { translations } from '../i18n';
import { useTranslation } from 'react-i18next';
import { CloseCircleTwoTone } from "@ant-design/icons";

const ErrorPage = () => {
    const { t } = useTranslation();
    return (
        <div className="success-container">
      <CloseCircleTwoTone twoToneColor="#ff4d4f" />
      <h3>{t(translations.paymentErrorTitle)}</h3>
      <Link to={`/`}>
        <Button size='large' type="primary" danger>{t(translations.goHome)}</Button>
      </Link>
    </div>
    )
}

export default ErrorPage;