import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import cn from 'classnames';
import { Row, Col } from "antd";
import Footer from "../components/Footer/Footer";
import Result from "../components/Result/Result";
import { LoadingStatus, MapWeeks } from "../types";
import { getPlan } from "../components/Form/api";
import { formatWeeks } from "../utils";


const ResultPage = () => {
  const { id } = useParams();
  const [weeks, setWeeks] = useState<MapWeeks | []>([]);
  const [parameters, setParameters] = useState({});
  const [loadStatus, setStatus] = useState(LoadingStatus.None)

  useEffect(() => {
    (async () => {
      if(id){
        const response = await getPlan(id)
        if(response.status === 200) {
          setWeeks(formatWeeks(response.data.weeks));
          setParameters(response.data.parameters)
          setStatus(LoadingStatus.Success)
        }
      }
    })();
  }, [id])

  return (
    <>
      <Result id={id} weeks={weeks} parameters={parameters} />
      <Row
        className={cn({
          "footer-absolute": loadStatus !== LoadingStatus.Success,
          "footer-relative": loadStatus === LoadingStatus.Success,
        })}
      >
        <Col span={24}>
          <Footer />
        </Col>
      </Row>
    </>
  );
};

export default ResultPage;
